<template>
  <div class="search-bar-container">
    <input 
      type="text" 
      v-model="localSearchQuery" 
      class="search-bar" 
      :placeholder="placeholder" 
      @input="emitSearchQuery"
    />
  </div>
</template>

<script>
export default {
  name: "TableListSearchBar",
  props: {
    placeholder: {
      type: String,
      default: "Search...",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localSearchQuery: this.modelValue, // Sync with parent using modelValue
    };
  },
  watch: {
    // Sync localSearchQuery when parent updates modelValue
    modelValue(newValue) {
      this.localSearchQuery = newValue;
    },
  },
  methods: {
    emitSearchQuery() {
      console.log("Search Query Emitted:", this.localSearchQuery); // Debugging
      this.$emit("update:modelValue", this.localSearchQuery.trim()); // Emit trimmed value
    },
  },
};
</script>

<style scoped>
.search-bar-container {
  width: 100%;
  max-width: 400px;
  margin: 10px auto;
  text-align: center;
}

.search-bar {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-shadow: none;
  outline: none;
  transition: border-color 0.3s;
}

.search-bar:focus {
  border-color: #38B35F;
}
</style>
