<template>
  <div class="modal-overlay" v-if="show">
    <div class="modal-box">
      <h2>{{ isUpdate ? "Update Schedule" : "Add Schedule" }}</h2>
      <form>
        <div class="form-group">
          <label for="day">Day:</label>
          <select v-model="scheduleData.day" required id="day">
            <option disabled value="">Select a day</option>
            <option v-for="day in daysOfWeek" :key="day" :value="day">
              {{ day }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="time">Time:</label>
          <select v-model="scheduleData.time" required id="time">
            <option disabled value="">Select a time</option>
            <option
              v-for="time in availableTimesWithAvailability"
              :key="time.time"
              :value="time.time"
              :disabled="time.unavailable"
            >
              {{ time.time }}
              <span v-if="time.isCurrent"> (Current)</span>
              <span v-if="time.unavailable">
                (Time slot in use by - {{ time.client }})</span
              >
            </option>
          </select>
        </div>

        <div class="button-group">
          <button type="button" class="btn-primary" @click="submitSchedule">
            {{ isUpdate ? "Update" : "Add" }} Schedule
          </button>
          <button type="button" class="btn-secondary" @click="closeModal">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "PipelineScheduleModal",
  props: {
    show: Boolean,
    client: Object,
    pipelineName: String,
    isUpdate: Boolean,
    availableTimes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      scheduleData: {
        day: "",
        time: "",
      },
      daysOfWeek: [
        "Sunday", // Start with Sunday as Cron uses 0 for Sunday
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      allSchedules: [], // To store all schedules from ClientPipelineSchedules
    };
  },
  computed: {
    availableTimesWithAvailability() {
      if (!this.scheduleData.day) {
        // If no day is selected, all times are available
        return this.availableTimes.map((time) => ({
          time,
          unavailable: false,
          client: null,
          isCurrent: false,
        }));
      }

      // Map all available times to check their availability
      return this.availableTimes.map((time) => {
        const conflict = this.allSchedules.find((schedule) => {
          const [minute, hour, , , dayNum] = schedule.cron.split(" ");
          const scheduleDay = this.daysOfWeek[parseInt(dayNum, 10)];
          const scheduleTime = `${hour.padStart(2, "0")}:${minute.padStart(
            2,
            "0"
          )}`;

          return (
            scheduleDay === this.scheduleData.day &&
            scheduleTime === time &&
            !(
              schedule.clientCode === this.client?.clientCode &&
              schedule.pipelineName === this.pipelineName
            )
          );
        });

        return {
          time,
          unavailable: !!conflict,
          client: conflict ? conflict.clientCode : null,
          isCurrent: time === this.scheduleData.time, // Mark if this is the current time
        };
      });
    },
  },
  watch: {
    show(newValue) {
      if (newValue && this.isUpdate && this.client?.schedules?.[0]) {
        this.initializeSchedule();
      } else {
        this.scheduleData = { day: "", time: "" };
      }
    },
  },
  methods: {
    setSchedules(schedules) {
      this.allSchedules = schedules;
    },
    initializeSchedule() {
      try {
        const existingSchedule = this.client.schedules[0];
        const [minute, hour, , , dayNum] = existingSchedule.Cron.split(" ");

        this.scheduleData.time = `${hour.padStart(2, "0")}:${minute.padStart(
          2,
          "0"
        )}`;

        const dayIndex = parseInt(dayNum, 10);
        if (dayIndex >= 0 && dayIndex < this.daysOfWeek.length) {
          this.scheduleData.day = this.daysOfWeek[dayIndex];
        }
      } catch (error) {
        console.error("Error parsing existing schedule:", error);
        this.scheduleData = { day: "", time: "" };
      }
    },
    submitSchedule() {
      if (!this.scheduleData.day || !this.scheduleData.time) {
        alert("Please select both day and time");
        return;
      }

      if (!this.client || !this.pipelineName) {
        console.error("Missing client or pipeline data:", {
          client: this.client,
          pipelineName: this.pipelineName,
        });
        alert("Missing client or pipeline information");
        return;
      }

      const scheduleSubmitData = {
        day: this.scheduleData.day,
        time: this.scheduleData.time,
        client: this.client,
        pipelineName: this.pipelineName,
      };

      console.log("Submitting schedule data:", scheduleSubmitData);

      this.$emit("submit", scheduleSubmitData);
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-box {
  background: #ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  font-family: "Roboto", sans-serif;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #0e223b;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #0e223b;
  margin-bottom: 8px;
}

select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

select:focus {
  border-color: #38b35f;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

button {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #0e223b;
  color: white;
}

.btn-primary:hover {
  background-color: #38b35f;
}

.btn-secondary {
  background-color: #e0e0e0;
  color: #333;
}

.btn-secondary:hover {
  background-color: #d6d6d6;
}
</style>
