<template>
  <div class="storage-tables-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img
              src="@/assets/optionsTechnology-logo.svg"
              alt="Options IT Logo"
            />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>
    <div class="storage-tables-list">
      <h1>App Deploy Table Viewer</h1>
      <p class="description">
        Select a table to view existing App Deployment Lists or Create and
        Update App Lists.
      </p>
      <div class="button-container">
        <button
          v-if="!selectedTable"
          @click="fetchTables"
          class="primary-button"
        >
          Refresh Tables
        </button>
        <button
          v-if="selectedTable"
          @click="showTableList"
          class="secondary-button"
        >
          Back to Table List
        </button>
        <router-link
          v-if="!selectedTable"
          to="/create-azure-storage-table"
          class="primary-button"
        >
          Create New Client Table
        </router-link>
        <router-link
          v-if="!selectedTable"
          to="/update-existing-table"
          class="primary-button"
        >
          Update Existing Table
        </router-link>
        <router-link
          v-if="!selectedTable"
          to="/AppDeploy-Onboarding"
          class="primary-button"
        >
          Onboard App Deploy Client
        </router-link>
      </div>

      <!-- Add the Search Bar -->
      <TableListSearchBar
        v-if="!selectedTable"
        v-model="searchQuery"
        placeholder="Search tables..."
      />

      <!-- Display Filtered Tables -->
      <ul v-if="!selectedTable" class="table-list">
        <li
          v-for="table in filteredTables"
          :key="table.name"
          @click="fetchTableContents(table.name)"
          class="table-item"
        >
          {{ table.name }}
        </li>
      </ul>

      <div v-if="selectedTable && tableContents.length" class="table-wrapper">
        <h2>{{ selectedTable }} Applications</h2>

        <!-- Message display section -->
        <div v-if="message.text" :class="['message', message.type]">
          {{ message.text }}
        </div>

        <div class="table-scroll-container">
          <div class="table-scrollbar" ref="topScroll">
            <div class="table-scroll-content"></div>
          </div>
          <div class="table-container" ref="tableContainer">
            <table>
              <thead>
                <tr>
                  <th
                    v-for="header in tableHeaders"
                    :key="header"
                    @click="sortTable(header)"
                  >
                    {{ header }}
                    <span v-if="sortColumn === header">
                      {{ sortOrder === "asc" ? "▲" : "▼" }}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in sortedTableContents"
                  :key="row.PartitionKey + '-' + row.rowKey"
                >
                  <td :class="statusColor(row.Status)" class="table-cell">
                    {{ row.Status }}
                  </td>
                  <td
                    v-for="header in filteredHeaders"
                    :key="header"
                    class="table-cell"
                  >
                    {{ row[header] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-scrollbar" ref="bottomScroll">
            <div class="table-scroll-content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/components/NavBar.vue";
import TableListSearchBar from "@/views/components/TableListSearchBar.vue";
import { msalInstance } from "@/auth.js";
import { listAzureTables, getTableContents } from "@/azure-devops-api.js";
import { mapState } from "vuex";

export default {
  components: {
    NavBar,
    TableListSearchBar,
  },
  data() {
    return {
      tables: [], // Holds all tables
      searchQuery: "", // Search bar input
      selectedTable: null, // Currently selected table
      tableContents: [], // Contents of the selected table
      tableHeaders: [], // Headers for the selected table
      sortColumn: "", // Column used for sorting
      sortOrder: "asc", // Sort direction
      message: {
        text: "",
        type: "",
      },
    };
  },
  computed: {
    ...mapState(["isLoggedIn"]),
    filteredTables() {
      const query = this.searchQuery.trim().toLowerCase();
      console.log("Filtering tables with query:", query);

      if (!query) return this.tables;

      const filtered = this.tables.filter((table) => {
        const tableName = table.name ? table.name.toLowerCase() : "";
        return tableName.startsWith(query);
      });

      console.log("Filtered Tables:", filtered);
      return filtered;
    },
    sortedTableContents() {
      if (!this.sortColumn) return this.tableContents;
      return this.tableContents.slice().sort((a, b) => {
        const aValue = a[this.sortColumn];
        const bValue = b[this.sortColumn];
        return this.sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    },
    filteredHeaders() {
      return this.tableHeaders.filter((header) => header !== "Status");
    },
  },
  async created() {
    try {
      console.log("Fetching tables...");
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: ["https://storage.azure.com/.default"],
        };
        await msalInstance.acquireTokenSilent(accessTokenRequest);
        this.tables = await listAzureTables();
        console.log("Tables loaded:", this.tables);
      }
    } catch (error) {
      console.error("Error fetching tables:", error);
    }
  },
  methods: {
    async fetchTables() {
      try {
        console.log("Refreshing tables...");
        const accounts = msalInstance.getAllAccounts();
        if (!accounts.length) throw new Error("No accounts found");
        const accessTokenRequest = {
          account: accounts[0],
          scopes: ["https://storage.azure.com/.default"],
        };
        await msalInstance.acquireTokenSilent(accessTokenRequest);
        this.tables = await listAzureTables();
        console.log("Refreshed tables:", this.tables);
      } catch (error) {
        console.error("Error refreshing tables:", error);
      }
    },
    async fetchTableContents(tableName) {
      try {
        console.log("Loading contents for:", tableName);
        this.selectedTable = tableName;
        this.tableContents = await getTableContents(tableName);
        this.tableHeaders = Object.keys(this.tableContents[0] || {});
        console.log("Loaded table contents:", this.tableContents);
      } catch (error) {
        console.error("Error loading table contents:", error);
      }
    },
    showTableList() {
      this.selectedTable = null;
    },
    sortTable(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }
    },
    statusColor(status) {
      return {
        "status-enabled": status === "Enabled",
        "status-disabled": status === "Disabled",
      };
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0e223b;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.top-bar .logo {
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
  margin-left: 20px;
}

.nav-bar {
  background: #0e223b;
  padding: 10px 0;
}

.description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38b35f;
}

.storage-tables-list {
  max-width: 1650px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.button-container {
  margin-bottom: 20px;
}

.primary-button,
.secondary-button {
  font-family: "Roboto", sans-serif;
  margin: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primary-button {
  background-color: #38b35f;
  color: white;
  text-decoration: none; /* Remove underline */
}

.primary-button:hover {
  background-color: #1c7ed6;
}

.secondary-button {
  background-color: #555;
  color: white;
}

.secondary-button:hover {
  background-color: #444;
}

ul.table-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

li.table-item {
  background-color: #0e223b;
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 1rem;
  padding: 15px 20px;
  border-radius: 4px;
  margin: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

li.table-item:hover {
  background-color: #1c7ed6;
}

.table-wrapper {
  width: 100%;
  overflow: hidden;
}

.table-scroll-container {
  position: relative;
  width: 100%;
}

.table-scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
  height: 20px;
}

.table-scroll-content {
  height: 1px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ddd;
}

table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  min-width: 600px;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust this value as needed */
}

th {
  background-color: #f4f4f4;
  cursor: pointer;
}

th span {
  font-size: 0.8rem;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.table-cell {
  max-width: 150px; /* Adjust this value as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message {
  margin: 20px 0;
  padding: 10px;
  border-radius: 4px;
  font-size: 1rem;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}

/* Dynamic Status CSS Classes */
.status-enabled {
  background-color: lightgreen;
}

.status-disabled {
  background-color: lightcoral;
}

/* Ensure status field gets specific width */
td.table-cell.status {
  max-width: 100px; /* Set a smaller width for status */
  font-weight: bold; /* Highlight the status */
  text-align: center;
}
</style>
