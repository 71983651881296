import axios from 'axios';
import { TableServiceClient, TableClient } from "@azure/data-tables";
import { InteractiveBrowserCredential } from "@azure/identity";

const apiUrlBase = 'https://dev.azure.com/cyaniccloud';
const apiVersion = '6.0';

const axiosInstance = axios.create({
  baseURL: apiUrlBase,
  headers: {
    'Content-Type': 'application/json',
  },
});

const setAuthHeader = (accessToken) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
};

const fetchProjects = async (accessToken) => {
  setAuthHeader(accessToken);
  try {
    const response = await axiosInstance.get(`_apis/projects?api-version=${apiVersion}`);
    return response.data.value;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

const fetchPipelines = async (accessToken) => {
  setAuthHeader(accessToken);
  try {
    const response = await axiosInstance.get(`/tenants/_apis/pipelines?api-version=7.1-preview.1`);
    return response.data.value;
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    throw error;
  }
};

const fetchPipelinesWithFilter = async (accessToken) => {
  setAuthHeader(accessToken);
  try {
    const response = await axiosInstance.get(`/tenants/_apis/pipelines?api-version=7.1-preview.1`);
    return response.data.value.filter(pipeline => {
      const folder = pipeline.folder.replace('\\', '').trim();
      return folder.length === 3;
    });
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    throw error;
  }
};

const fetchPipelinesWithFilterandProject = async (accessToken, projectName) => {
  setAuthHeader(accessToken);
  try {
    const response = await axiosInstance.get(`/${projectName}/_apis/pipelines?api-version=7.1-preview.1`);
    return response.data.value.filter(pipeline => {
      const folder = pipeline.folder.replace('\\', '').trim();
      // Include pipelines where folder length is 3 or folder contains "Deploy Baseline Tenant Configuration" or "Capture Client Configuration Pipeline"
      return folder.length === 3 || folder.includes('Deploy Baseline Tenant Configuration');
    });
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    throw error;
  }
};


const fetchPipelinesbyName = async (accessToken, projectName) => {
  setAuthHeader(accessToken);
  try {
    const response = await axiosInstance.get(`/${projectName}/_apis/pipelines?api-version=7.1-preview.1`);
    return response.data.value.filter(pipeline => {
      const folder = pipeline.folder.charAt(0) === '\\' ? pipeline.folder.slice(1) : pipeline.folder;
      return folder.length === 3;
    });
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    throw error;
  }
};

const runPipeline = async (accessToken, projectId, pipelineId) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineId}/runs?api-version=7.1-preview.1`;

  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "variables": {}
  };

  try {
    await axiosInstance.post(apiUrl, body);
  } catch (error) {
    console.error('Error running pipeline:', error);
    throw error;
  }
};

const runFullBaselinePipeline = async (accessToken, projectId, pipelineId) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineId}/runs?api-version=7.1-preview.1`;

  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "variables": {}  // Assuming no variables are needed here
  };

  try {
    const response = await axiosInstance.post(apiUrl, body);

    // Return the run ID (as done in `triggerOnboardBuild`)
    return response.data.id;
  } catch (error) {
    console.error('Error running pipeline:', error);
    throw error;
  }
};

const runCustomBaselinePipeline = async (accessToken, projectId, pipelineId, componentsJson = null) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineId}/runs?api-version=7.1-preview.1`;

  // Base body structure for pipeline run
  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "templateParameters": {} // Use templateParameters for passing parameters
  };

  // If componentsJson is provided, include it in the templateParameters
  if (componentsJson) {
    body.templateParameters.Components = componentsJson; // Pass as a parameter
  }

  try {
    const response = await axiosInstance.post(apiUrl, body);

    // Return the run ID like in `runFullBaselinePipeline`
    return response.data.id;  // Ensure it returns the run ID
  } catch (error) {
    console.error('Error running pipeline:', error);
    throw error;
  }
};


const runComponentPipeline = async (accessToken, projectId, pipelineId, componentsJson = null) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineId}/runs?api-version=7.1-preview.1`;

  // Base body structure for pipeline run
  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "variables": {}
  };

  // If componentsJson is provided, include it in the variables
  if (componentsJson) {
    body.variables.Components = {
      value: componentsJson,
      isSecret: false
    };
  }

  try {
    await axiosInstance.post(apiUrl, body);
  } catch (error) {
    console.error('Error running pipeline:', error);
    throw error;
  }
};

const runComponentPipelinev2 = async (accessToken, projectId, pipelineId, componentsJson = null) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineId}/runs?api-version=7.1-preview.1`;

  // Base body structure for pipeline run
  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "templateParameters": {} // Use templateParameters for passing parameters
  };

  // If componentsJson is provided, include it in the templateParameters
  if (componentsJson) {
    body.templateParameters.Components = componentsJson; // Pass as a parameter
  }

  try {
    await axiosInstance.post(apiUrl, body);
  } catch (error) {
    console.error('Error running pipeline:', error);
    throw error;
  }
};


const triggerBuild = async (accessToken, projectId, pipelineBuildId, appName) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineBuildId}/runs?api-version=7.1-preview.1`;
  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "templateParameters": {
      "AppList": `- ${appName}`
    },
    "variables": {}
  };

  try {
    const response = await axiosInstance.post(apiUrl, body);
    return response.data;
  } catch (error) {
    console.error('Error triggering build:', error);
    throw error;
  }
};

const triggerAppDeployBuild = async (accessToken, projectId, pipelineBuildId, ClientCode, Domain, UserName, Password) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineBuildId}/runs?api-version=7.1-preview.1`;
  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "templateParameters": {
      "ClientCode": ClientCode,
      "Domain": Domain
    },
    "variables": {
      "TenantAdminUserName": {
        "isSecret": false,
        "value": UserName
      },
      "TenantAdminUserPW": {
        "isSecret": true,
        "value": Password
      }
    }
  };

  try {
    const response = await axiosInstance.post(apiUrl, body);
    return response.data;
  } catch (error) {
    console.error('Error triggering build:', error);
    throw error;
  }
};

const fetchJsonFile = async (accessToken, repoId, path) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/1b238677-4a8d-4243-827e-88a0558c085a/_apis/git/repositories/${repoId}/items?path=${encodeURIComponent(path)}&recursionLevel=0&includeContentMetadata=true&versionDescriptor.version=main&versionDescriptor.versionOptions=0&versionDescriptor.versionType=0&includeContent=true&resolveLfs=true`;

  try {
    const response = await axiosInstance.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error(`Error fetching JSON file from ${path}:`, error);
    throw error;
  }
};

const fetchYamlFile = async (accessToken, repoId, path) => {
  setAuthHeader(accessToken);

  // Correct project ID in the URL
  const projectId = '65a332c6-445e-4c72-a097-db3513d145d5';

  // Correct API endpoint
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/git/repositories/${repoId}/items?path=${encodeURIComponent(path)}&recursionLevel=0&includeContentMetadata=true&versionDescriptor.version=main&versionDescriptor.versionOptions=0&versionDescriptor.versionType=0&includeContent=true&resolveLfs=true`;

  try {
    const response = await axiosInstance.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error(`Error fetching YAML file from ${path}:`, error);
    throw error;
  }
};


const fetchClients = async (accessToken, repoId, path) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/1b238677-4a8d-4243-827e-88a0558c085a/_apis/git/repositories/${repoId}/items?scopePath=${encodeURIComponent(path)}&recursionLevel=OneLevel&api-version=6.0`;

  try {
    const response = await axiosInstance.get(apiUrl);
    const directories = response.data.value.filter(item => item.isFolder);
    return directories.map(dir => dir.path.split('/').pop());
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};


const compareJsonObjects = (referenceObject, differenceObject, basePath = '', excludedProperties = []) => {
  let comparisonResults = [];

  for (let property in referenceObject) {
    const fullPropertyPath = basePath ? `${basePath}.${property}` : property;

    if (excludedProperties.includes(fullPropertyPath)) {
      continue;
    }

    const refValue = referenceObject[property];
    const diffValue = differenceObject[property];

    if (diffValue === undefined) {
      comparisonResults.push({
        Path: fullPropertyPath,
        RefValue: refValue,
        DiffValue: 'Missing',
        Equal: false
      });
      continue;
    }

    if (Array.isArray(refValue) && Array.isArray(diffValue)) {
      const arraysEqual = JSON.stringify(refValue) === JSON.stringify(diffValue);
      comparisonResults.push({
        Path: fullPropertyPath,
        RefValue: JSON.stringify(refValue),
        DiffValue: JSON.stringify(diffValue),
        Equal: arraysEqual
      });
    } else if (typeof refValue === 'object' && refValue !== null && typeof diffValue === 'object' && diffValue !== null) {
      const subResults = compareJsonObjects(refValue, diffValue, fullPropertyPath, excludedProperties);
      comparisonResults = comparisonResults.concat(subResults);
    } else {
      const equal = refValue === diffValue;
      comparisonResults.push({
        Path: fullPropertyPath,
        RefValue: refValue,
        DiffValue: diffValue,
        Equal: equal
      });
    }
  }

  for (let property in differenceObject) {
    const fullPropertyPath = basePath ? `${basePath}.${property}` : property;
    if (!Object.prototype.hasOwnProperty.call(referenceObject, property) && !excludedProperties.includes(fullPropertyPath)) {
      comparisonResults.push({
        Path: fullPropertyPath,
        RefValue: 'Missing',
        DiffValue: differenceObject[property],
        Equal: false
      });
    }
  }

  return comparisonResults;
};

const compareConfigurations = async (accessToken, baselineUrl, clientUrl) => {
  // Set headers for authentication
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  };

  try {
    // Fetch baseline and client data
    const [baselineResponse, clientResponse] = await Promise.all([
      axios.get(baselineUrl, { headers }),
      axios.get(clientUrl, { headers })
    ]);

    // Parse content
    const baselineContent = JSON.parse(baselineResponse.data.content);
    const clientContent = JSON.parse(clientResponse.data.content);

    // Compare and group by displayName (or any other key of interest)
    let comparisonResults = {};
    if (baselineContent.displayName === clientContent.displayName) {
      const group = baselineContent.displayName;
      comparisonResults[group] = comparisonResults[group] || [];

      // Here, add a comparison logic for each property in JSON
      Object.keys(baselineContent).forEach((key) => {
        const baselineValue = baselineContent[key];
        const clientValue = clientContent[key];

        comparisonResults[group].push({
          Path: key,
          RefValue: baselineValue,
          DiffValue: clientValue,
          Equal: baselineValue === clientValue
        });
      });
    }

    return comparisonResults;

  } catch (error) {
    console.error('Error comparing configurations:', error);
    throw error;
  }
};


const fetchAndCompareFiles = async (accessToken, repo, baselinePath, clientPath, excludedProperties) => {
  try {
    const baselineFile = await fetchJsonFile(accessToken, repo, baselinePath);
    const clientFile = await fetchJsonFile(accessToken, repo, clientPath);

    const baselineContent = JSON.parse(baselineFile.content);
    const clientContent = JSON.parse(clientFile.content);

    const comparisonResults = compareJsonObjects(baselineContent, clientContent, '', excludedProperties);
    console.log(comparisonResults);

    return comparisonResults;
  } catch (error) {
    console.error('Error comparing files:', error);
    throw error;
  }
};

const fetchAllJsonFiles = async (accessToken, repoId, path) => {
  const apiUrl = `https://dev.azure.com/cyaniccloud/1b238677-4a8d-4243-827e-88a0558c085a/_apis/git/repositories/${repoId}/items?scopePath=${encodeURIComponent(path)}&recursionLevel=Full&api-version=6.0`;
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(apiUrl, { headers });
    const files = response.data.value.filter(item => !item.isFolder && item.path.endsWith('.json'));
    return files.map(file => file.path);
  } catch (error) {
    console.error(`Error fetching JSON files from ${path}:`, error);
    throw error;
  }
};


const fetchAllJsonFilesv2 = async (accessToken, repoId, path, progressCallback) => {
  const apiUrl = `https://dev.azure.com/cyaniccloud/1b238677-4a8d-4243-827e-88a0558c085a/_apis/git/repositories/${repoId}/items?scopePath=${encodeURIComponent(path)}&recursionLevel=Full&api-version=6.0`;
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(apiUrl, { headers });
    const files = response.data.value.filter(item => !item.isFolder && item.path.endsWith('.json'));

    // Initialize progress tracking
    const totalFiles = files.length;
    let processedFiles = 0;

    // Report progress after each file is processed
    for (let i = 0; i < totalFiles; i++) {
      processedFiles += 1;
      if (progressCallback) {
        const progress = Math.floor((processedFiles / totalFiles) * 100);
        progressCallback(progress);
      }
    }

    return files.map(file => file.path);
  } catch (error) {
    console.error(`Error fetching JSON files from ${path}:`, error);
    throw error;
  }
};


const fetchAndCompareAllFiles = async (accessToken, repoId, baselineDir, clientDir, excludedProperties) => {
  try {
    const baselineFiles = await fetchAllJsonFiles(accessToken, repoId, baselineDir);
    const clientFiles = await fetchAllJsonFiles(accessToken, repoId, clientDir);

    let comparisonResults = [];

    for (let baselineFile of baselineFiles) {
      const relativePath = baselineFile.replace(baselineDir, '');
      const clientFilePath = clientFiles.find(clientFile => clientFile.endsWith(relativePath));

      if (clientFilePath) {
        const baselineFileContent = await fetchJsonFile(accessToken, repoId, baselineFile);
        const clientFileContent = await fetchJsonFile(accessToken, repoId, clientFilePath);

        const baselineContent = JSON.parse(baselineFileContent.content);
        const clientContent = JSON.parse(clientFileContent.content);

        const results = compareJsonObjects(baselineContent, clientContent, '', excludedProperties);
        comparisonResults = comparisonResults.concat(results);
      } else {
        comparisonResults.push({
          Path: relativePath,
          RefValue: JSON.parse((await fetchJsonFile(accessToken, repoId, baselineFile)).content),
          DiffValue: 'Missing',
          Equal: false
        });
      }
    }

    return comparisonResults;
  } catch (error) {
    console.error('Error comparing files:', error);
    throw error;
  }
};

const fetchAndCompareAllFilesv2 = async (accessToken, repoId, baselineDir, clientDir, excludedProperties) => {
  try {
    const baselineFiles = await fetchAllJsonFiles(accessToken, repoId, baselineDir);
    const clientFiles = await fetchAllJsonFiles(accessToken, repoId, clientDir);

    let groupedComparisonResults = {}; // Ensure this is initialized correctly

    for (let baselineFile of baselineFiles) {
      const relativePath = baselineFile.replace(baselineDir, '');
      const clientFilePath = clientFiles.find(clientFile => clientFile.endsWith(relativePath));

      let results = [];
      if (clientFilePath) {
        const baselineFileContent = await fetchJsonFile(accessToken, repoId, baselineFile);
        const clientFileContent = await fetchJsonFile(accessToken, repoId, clientFilePath);

        const baselineContent = JSON.parse(baselineFileContent.content);
        const clientContent = JSON.parse(clientFileContent.content);

        results = compareJsonObjects(baselineContent, clientContent, '', excludedProperties);
      } else {
        results.push({
          Path: relativePath,
          RefValue: JSON.parse((await fetchJsonFile(accessToken, repoId, baselineFile)).content),
          DiffValue: 'Missing',
          Equal: false
        });
      }

      // Ensure that results for each file are stored in groupedComparisonResults
      groupedComparisonResults[relativePath] = results; // Organize by file/policy
    }

    return groupedComparisonResults; // Return results grouped by file/policy
  } catch (error) {
    console.error('Error comparing files:', error);
    throw error;
  }
};

const fetchAndCompareAllFilesv3 = async (accessToken, repoId, baselineDir, clientDir, excludedProperties) => {
  try {
    const baselineFiles = await fetchAllJsonFiles(accessToken, repoId, baselineDir);
    const clientFiles = await fetchAllJsonFiles(accessToken, repoId, clientDir);

    let groupedComparisonResults = {}; // Object to store comparison results grouped by policy

    for (let baselineFile of baselineFiles) {
      const relativePath = baselineFile.replace(baselineDir, '');
      const clientFilePath = clientFiles.find(clientFile => clientFile.endsWith(relativePath));

      let results = [];
      if (clientFilePath) {
        const baselineFileContent = await fetchJsonFile(accessToken, repoId, baselineFile);
        const clientFileContent = await fetchJsonFile(accessToken, repoId, clientFilePath);

        const baselineContent = JSON.parse(baselineFileContent.content);
        const clientContent = JSON.parse(clientFileContent.content);

        results = compareJsonObjects(baselineContent, clientContent, '', excludedProperties);
      } else {
        results.push({
          Path: relativePath,
          RefValue: JSON.parse((await fetchJsonFile(accessToken, repoId, baselineFile)).content),
          DiffValue: 'Missing',
          Equal: false
        });
      }

      // Organize results by the configuration name (e.g., displayName or policy name)
      const configName = relativePath.split('/').pop().replace('.json', '');
      groupedComparisonResults[configName] = results;
    }

    return groupedComparisonResults; // Return results grouped by policy
  } catch (error) {
    console.error('Error comparing files:', error);
    throw error;
  }
};

const fetchAndCompareAllFilesv4 = async (accessToken, repoId, baselineDir, clientDir, excludedProperties, progressCallback) => {
  try {
    const baselineFiles = await fetchAllJsonFiles(accessToken, repoId, baselineDir);
    const clientFiles = await fetchAllJsonFiles(accessToken, repoId, clientDir);

    let groupedComparisonResults = {};
    const totalFiles = baselineFiles.length; // Total files for progress calculation

    for (let i = 0; i < totalFiles; i++) {
      const baselineFile = baselineFiles[i];
      const relativePath = baselineFile.replace(baselineDir, '');
      const clientFilePath = clientFiles.find(clientFile => clientFile.endsWith(relativePath));

      let results = [];
      if (clientFilePath) {
        const baselineFileContent = await fetchJsonFile(accessToken, repoId, baselineFile);
        const clientFileContent = await fetchJsonFile(accessToken, repoId, clientFilePath);

        const baselineContent = JSON.parse(baselineFileContent.content);
        const clientContent = JSON.parse(clientFileContent.content);

        results = compareJsonObjects(baselineContent, clientContent, '', excludedProperties);
      } else {
        results.push({
          Path: relativePath,
          RefValue: JSON.parse((await fetchJsonFile(accessToken, repoId, baselineFile)).content),
          DiffValue: 'Missing',
          Equal: false
        });
      }

      const configName = relativePath.split('/').pop().replace('.json', '');
      groupedComparisonResults[configName] = results;

      // Calculate and update progress
      const progress = Math.floor(((i + 1) / totalFiles) * 100);
      if (progressCallback) {
        progressCallback(progress); // Call the callback with the progress
      }
    }

    return groupedComparisonResults;
  } catch (error) {
    console.error('Error comparing files:', error);
    throw error;
  }
};
const fetchAndCompareAllFilesv5 = async (accessToken, repoId, baselineDir, clientDir, excludedProperties, progressCallback) => {
  try {
    const baselineFiles = await fetchAllJsonFiles(accessToken, repoId, baselineDir);
    const clientFiles = await fetchAllJsonFiles(accessToken, repoId, clientDir);

    let groupedComparisonResults = {};
    const totalFiles = baselineFiles.length;

    // Get the grouped items structure to use for area categorization
    const groupedItems = await getAllItemsGroupedByTopLevelArea(accessToken);

    for (let i = 0; i < totalFiles; i++) {
      const baselineFile = baselineFiles[i];
      const relativePath = baselineFile.replace(baselineDir, '');
      const clientFilePath = clientFiles.find(clientFile => clientFile.endsWith(relativePath));

      let results = [];
      if (clientFilePath) {
        const baselineFileContent = await fetchJsonFile(accessToken, repoId, baselineFile);
        const clientFileContent = await fetchJsonFile(accessToken, repoId, clientFilePath);

        const baselineContent = JSON.parse(baselineFileContent.content);
        const clientContent = JSON.parse(clientFileContent.content);

        results = compareJsonObjects(baselineContent, clientContent, '', excludedProperties);
      } else {
        results.push({
          Path: relativePath,
          RefValue: JSON.parse((await fetchJsonFile(accessToken, repoId, baselineFile)).content),
          DiffValue: 'Missing',
          Equal: false
        });
      }

      // Determine the config name and area from groupedItems
      const configName = relativePath.split('/').pop().replace('.json', '');
      const area = Object.keys(groupedItems).find(area =>
        groupedItems[area].some(item => item.path.endsWith(relativePath))
      );

      // Include area as part of grouped comparison results
      if (!groupedComparisonResults[area]) {
        groupedComparisonResults[area] = {};
      }
      groupedComparisonResults[area][configName] = results;

      // Calculate and update progress
      const progress = Math.floor(((i + 1) / totalFiles) * 100);
      if (progressCallback) {
        progressCallback(progress);
      }
    }

    return groupedComparisonResults;
  } catch (error) {
    console.error('Error comparing files:', error);
    throw error;
  }
};



// Define your Azure Storage account name and key here
const accountName = 'cccadostrgwe';

// Function to create a TableServiceClient
async function createTableServiceClient() {
  const credential = new InteractiveBrowserCredential({
    clientId: '1da955b1-4ce4-481a-a857-0423ac697927', // replace with your client ID
    tenantId: 'bcc93690-805b-4911-b8c1-6e447795ef8b', // replace with your tenant ID
  });
  return new TableServiceClient(
    `https://${accountName}.table.core.windows.net`,
    credential
  );
}

const listAzureTables = async () => {
  const tableServiceClient = await createTableServiceClient();
  const tables = tableServiceClient.listTables();
  const result = [];
  for await (const table of tables) {
    result.push(table);
  }
  return result;
};

// Function to get table contents
const getTableContents = async (tableName) => {
  const credential = new InteractiveBrowserCredential({
    clientId: '1da955b1-4ce4-481a-a857-0423ac697927', // replace with your client ID
    tenantId: 'bcc93690-805b-4911-b8c1-6e447795ef8b', // replace with your tenant ID
  });
  const tableClient = new TableClient(
    `https://${accountName}.table.core.windows.net`,
    tableName,
    credential
  );
  const entitiesIter = tableClient.listEntities();
  const entities = [];
  for await (const entity of entitiesIter) {
    entities.push(entity);
  }
  return entities;
};

// Function to create a client table

const createClientTable = async (tableName) => {
  const tableServiceClient = await createTableServiceClient();
  let status = 201;
  let message = `Table '${tableName}' created successfully.`;

  try {
    await tableServiceClient.createTable(tableName, {
      onResponse: (response) => {
        if (response.status === 409) {
          status = 409;
          message = `Table '${tableName}' already exists.`;
        }
      }
    });
  } catch (error) {
    status = error.statusCode || 500;
    message = error.message || 'An unexpected error occurred';
  }

  return { status, message };
};
// Function to update a client table
const updateClientTable = async (tableName, entity) => {
  const tableServiceClient = await createTableServiceClient();
  try {

    const tableClient = new TableClient(
      `https://${accountName}.table.core.windows.net`,
      tableName,
      tableServiceClient
    );

    await tableClient.createEntity(entity);
    return { status: 200, message: `Table '${tableName}' updated successfully.` };
  } catch (error) {
    return { status: error.statusCode || 500, message: error.message || 'An unexpected error occurred' };
  }
};

const updateEntity = async (tableName, entity) => {
  const tableServiceClient = await createTableServiceClient();
  try {
    const tableClient = new TableClient(
      `https://${accountName}.table.core.windows.net`,
      tableName,
      tableServiceClient
    );

    await tableClient.updateEntity(entity, "Replace");
    return { status: 200, message: `Entity in table '${tableName}' updated successfully.` };
  } catch (error) {
    return { status: error.statusCode || 500, message: error.message || 'An unexpected error occurred' };
  }
};

const deleteClientTable = async (tableName, partitionKey, rowKey) => {
  const credential = await createTableServiceClient();
  try {
    const tableClient = new TableClient(
      `https://${accountName}.table.core.windows.net`,
      tableName,
      credential
    );

    await tableClient.deleteEntity(partitionKey, rowKey);
    return { status: 200, message: `Entity deleted successfully.` };
  } catch (error) {
    console.error('Error deleting entity:', error);
    return { status: error.statusCode || 500, message: error.message || 'An unexpected error occurred' };
  }
};

const getPipelineStatus = async (accessToken, projectId, pipelineId, runId) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineId}/runs/${runId}?api-version=7.1-preview.1`;

  try {
    const response = await axiosInstance.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching pipeline status:', error);
    throw error;
  }
};


const triggerOnboardBuild = async (accessToken, projectId, pipelineBuildId, ClientCode, Domain, UserName, Password) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineBuildId}/runs?api-version=7.1-preview.1`;
  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "templateParameters": {
      "ClientCode": ClientCode,
      "Domain": Domain
    },
    "variables": {
      "TenantAdminUserName": {
        "isSecret": false,
        "value": UserName
      },
      "TenantAdminUserPW": {
        "isSecret": true,
        "value": Password
      }
    }
  };

  try {
    const response = await axiosInstance.post(apiUrl, body);
    return response.data.id; // Return the run ID of the triggered build
  } catch (error) {
    console.error('Error triggering build:', error);
    throw error;
  }
};

const updateYamlFileContent = async (accessToken, repoId, path, content) => {
  setAuthHeader(accessToken);

  // Step 1: Get the latest commit ID
  const repoUrl = `https://dev.azure.com/cyaniccloud/_apis/git/repositories/${repoId}`;
  const refsUrl = `${repoUrl}/refs?filter=heads/main&api-version=6.0`;

  try {
    // Get the latest commit ID
    const refsResponse = await axiosInstance.get(refsUrl);
    const commitId = refsResponse.data.value[0].objectId;

    // Step 2: Create a new commit with the updated content
    const updateUrl = `${repoUrl}/pushes?api-version=6.0`;
    const body = {
      "refUpdates": [
        {
          "name": "refs/heads/main",
          "oldObjectId": commitId
        }
      ],
      "commits": [
        {
          "comment": "Updating YAML file with client application list",
          "changes": [
            {
              "changeType": "edit",
              "item": {
                "path": path
              },
              "newContent": {
                "content": content,
                "contentType": "rawtext"
              }
            }
          ]
        }
      ]
    };

    // Make the request to update the YAML file
    await axiosInstance.post(updateUrl, body);
    console.log("YAML file updated successfully.");
  } catch (error) {
    console.error("Error updating YAML file:", error);
    throw error;
  }
};






const getRepositories = async (accessToken) => {
  setAuthHeader(accessToken);
  const apiUrl = "https://dev.azure.com/cyaniccloud/1b238677-4a8d-4243-827e-88a0558c085a/_apis/git/repositories?api-version=6.0";

  try {
    const response = await axiosInstance.get(apiUrl);
    return response.data.value;
  } catch (error) {
    console.error("Error fetching repositories:", error);
    throw error;
  }
};

const getFolderStructureAndFiles = async (accessToken, repoId, path, progressCallback) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/1b238677-4a8d-4243-827e-88a0558c085a/_apis/git/repositories/${repoId}/items?scopePath=${encodeURIComponent(
    path
  )}&recursionLevel=Full&api-version=6.0`;

  try {
    const response = await axiosInstance.get(apiUrl);
    const items = response.data.value;

    // Initialize progress tracking
    const totalItems = items.length;
    let processedItems = 0;

    // Report progress after each item is processed
    for (let i = 0; i < totalItems; i++) {
      processedItems += 1;
      if (progressCallback) {
        const progress = Math.floor((processedItems / totalItems) * 100);
        progressCallback(progress);
      }
    }

    return items;
  } catch (error) {
    console.error(`Error fetching items from ${path}:`, error);
    throw error;
  }
};


const getAllItemsGroupedByTopLevelArea = async (accessToken, progressCallback) => {
  try {
    const repositories = await getRepositories(accessToken);
    const allItems = [];

    for (const repo of repositories) {
      const items = await getFolderStructureAndFiles(accessToken, repo.id, "/M365Config/Resources", progressCallback);

      items.forEach((item) => {
        if (item.path) {
          const match = item.path.match("^/M365Config/Resources/([^/]+)");
          const topLevelArea = item.path === "/M365Config/Resources" ? "OverallBaseline" : match ? match[1] : "Uncategorized";

          allItems.push({
            repository: repo.name,
            path: item.path,
            type: item.gitObjectType,
            area: topLevelArea, // New property for grouping
          });
        }
      });
    }

    // Group items by top-level area
    const groupedItems = allItems.reduce((acc, item) => {
      if (!acc[item.area]) {
        acc[item.area] = [];
      }
      acc[item.area].push(item);
      return acc;
    }, {});

    return groupedItems;
  } catch (error) {
    console.error("Error processing items:", error);
  }
};


const getAllPipelineDefinitions = async (organization, projectName, accessToken) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  };
  const pipelinesUrl = `https://dev.azure.com/${organization}/${projectName}/_apis/build/definitions?api-version=7.1-preview.7`;

  try {
    const response = await fetch(pipelinesUrl, { headers });
    const data = await response.json();
    return data.value;
  } catch (error) {
    console.error('Error fetching pipeline definitions:', error.message);
    throw error;
  }
};

const getPipelineDefinitionInfo = async (pipelineName, folder, organization, projectName, accessToken) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  };
  const pipelinesUrl = `https://dev.azure.com/${organization}/${projectName}/_apis/build/definitions?api-version=7.1-preview.7`;

  try {
    const response = await fetch(pipelinesUrl, { headers });
    const data = await response.json();

    const pipeline = data.value.find(
      (item) => item.name === pipelineName && item.path === folder
    );

    if (!pipeline) {
      console.log(`No pipeline found for ${pipelineName} in ${folder}`);
      return null;
    }

    return {
      definitionId: pipeline.id,
      projectId: pipeline.project.id,
    };
  } catch (error) {
    console.error('Error fetching pipeline definition info:', error.message);
    throw error;
  }
};

const getExistingSchedules = async (organization, projectId, projectName, definitionId, folder, accessToken) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  try {
    // Get build definition
    const definitionUrl = `https://dev.azure.com/${organization}/${projectId}/_apis/build/definitions/${definitionId}?api-version=7.0&expand=properties`;
    const definitionResponse = await fetch(definitionUrl, { headers });

    if (!definitionResponse.ok) {
      throw new Error(`Failed to fetch build definition: ${definitionResponse.statusText}`);
    }

    const buildDefinition = await definitionResponse.json();

    // Get YAML content
    const yamlPath = buildDefinition.process.yamlFilename;
    const repoId = buildDefinition.repository.id;
    const yamlUrl = `https://dev.azure.com/${organization}/${projectName}/_apis/git/repositories/${repoId}/items`;

    const yamlHeaders = {
      ...headers,
      Accept: 'text/plain',
    };

    const yamlResponse = await fetch(`${yamlUrl}?path=${encodeURIComponent(yamlPath)}&api-version=7.0`, { headers: yamlHeaders });

    if (!yamlResponse.ok) {
      const errorText = await yamlResponse.text();
      throw new Error(`YAML fetch error: ${yamlResponse.status} - ${errorText}`);
    }

    const yamlString = await yamlResponse.text();
    //console.log("Fetched YAML Content (raw):", yamlString);

    // Check for schedules section
    const schedulePattern = /^\s*schedules:\s*(-\s*cron:.*?(?=\n\S))/ms;
    const scheduleMatch = yamlString.match(schedulePattern);

    if (!scheduleMatch) {
      console.log(`No schedules found in YAML for definition ID ${definitionId} in folder ${folder}`);
      return null;
    }

    const schedulesBlock = scheduleMatch[1];

    // Parse individual schedules
    const cronPattern = /-\s*cron:\s*['"]([^'"]+)['"]\s*displayName:\s*([^\n]+)\s*branches:\s*.*?include:\s*-\s*([^\n]+)\s*always:\s*(\w+)/g;
    const schedules = [];
    let match;

    while ((match = cronPattern.exec(schedulesBlock)) !== null) {
      schedules.push({
        Cron: match[1],
        DisplayName: match[2].trim(),
        Branches: match[3].trim(),
        Always: match[4] === 'true',
      });
    }

    if (schedules.length === 0) {
      console.log(`No matching schedules found in YAML block for definition ID ${definitionId} in folder ${folder}`);
      return null;
    }

    return schedules;

  } catch (error) {
    console.error(`Failed to retrieve schedules for definition ID ${definitionId} in folder ${folder}`);
    console.error('Error:', error);
    return null;
  }
};

const UpdateClientSchedule = async (
  organization,
  projectId,
  projectName,
  definitionId,
  scheduleDetails,
  accessToken
) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  try {
    // Step 1: Fetch the current pipeline definition
    const definitionUrl = `https://dev.azure.com/${organization}/${projectId}/_apis/build/definitions/${definitionId}?api-version=7.0`;
    const definitionResponse = await fetch(definitionUrl, { headers });
    const definition = await definitionResponse.json();

    // Step 2: Get the current YAML content
    const yamlPath = definition.process.yamlFilename;
    const repoId = definition.repository.id;
    const yamlUrl = `https://dev.azure.com/${organization}/${projectName}/_apis/git/repositories/${repoId}/items`;

    const yamlResponse = await fetch(`${yamlUrl}?path=${yamlPath}&api-version=7.0`, {
      headers: {
        ...headers,
        Accept: 'text/plain',
      },
    });

    if (!yamlResponse.ok) {
      throw new Error('Failed to fetch YAML content');
    }

    let yamlContent = await yamlResponse.text();

    // Step 3: Create a new schedule block
    const newSchedule = `
  - cron: '${scheduleDetails.cronExpression}'
    displayName: ${scheduleDetails.displayName}
    branches:
      include:
        - main
    always: true`;

    // Step 4: Replace or add the schedules block
    if (yamlContent.includes('schedules:')) {
      // Replace the entire schedules block with the new schedule
      yamlContent = yamlContent.replace(
        /schedules:\s*\n\s*-\s*cron:.*?(?=\n\S|$)/s,
        `schedules:${newSchedule}\n\n`
      );
    } else {
      // Insert schedules block after "trigger: none"
      yamlContent = yamlContent.replace(
        /(trigger:\s*none\s*\n)/,
        `$1\nschedules:${newSchedule}\n\n`
      );
    }

    // Step 5: Update the YAML file content in the repository
    await updateYamlFileContentSchedule(accessToken, repoId, yamlPath, yamlContent);

    console.log('Schedule replaced successfully and YAML file updated.');
    return {
      success: true,
      message: 'Schedule replaced and YAML file updated successfully',
    };
  } catch (error) {
    console.error('Error updating client schedule:', error);
    throw error;
  }
};


const updateYamlFileContentSchedule = async (accessToken, repoId, path, content) => {
  setAuthHeader(accessToken);

  // Step 1: Get the latest commit ID
  const repoUrl = `https://dev.azure.com/cyaniccloud/_apis/git/repositories/${repoId}`;
  const refsUrl = `${repoUrl}/refs?filter=heads/main&api-version=6.0`;

  try {
    // Get the latest commit ID
    const refsResponse = await axiosInstance.get(refsUrl);
    const commitId = refsResponse.data.value[0].objectId;

    // Step 2: Create a new commit with the updated content
    const updateUrl = `${repoUrl}/pushes?api-version=6.0`;
    const body = {
      "refUpdates": [
        {
          "name": "refs/heads/main",
          "oldObjectId": commitId
        }
      ],
      "commits": [
        {
          "comment": "Updating YAML file with client schedule",
          "changes": [
            {
              "changeType": "edit",
              "item": {
                "path": path
              },
              "newContent": {
                "content": content,
                "contentType": "rawtext"
              }
            }
          ]
        }
      ]
    };

    // Make the request to update the YAML file
    await axiosInstance.post(updateUrl, body);
    console.log("YAML file updated successfully.");
  } catch (error) {
    console.error("Error updating YAML file:", error);
    throw error;
  }
};

export {
  fetchProjects,
  fetchPipelines,
  runPipeline,
  fetchPipelinesbyName,
  triggerBuild,
  fetchJsonFile,
  fetchClients,
  compareJsonObjects,
  fetchAndCompareFiles,
  fetchAndCompareAllFiles,
  fetchAndCompareAllFilesv2,
  fetchAndCompareAllFilesv3,
  fetchAndCompareAllFilesv4,
  fetchAndCompareAllFilesv5,
  compareConfigurations,
  fetchAllJsonFiles,
  fetchAllJsonFilesv2,
  listAzureTables,
  getTableContents,
  createClientTable,
  updateClientTable,
  updateEntity,
  deleteClientTable,
  triggerAppDeployBuild,
  fetchPipelinesWithFilter,
  fetchPipelinesWithFilterandProject,
  runComponentPipeline,
  runComponentPipelinev2,
  getPipelineStatus,
  triggerOnboardBuild,
  fetchYamlFile,
  updateYamlFileContent,
  runFullBaselinePipeline,
  runCustomBaselinePipeline,
  getRepositories,
  getFolderStructureAndFiles,
  getAllItemsGroupedByTopLevelArea,
  getAllPipelineDefinitions,
  getPipelineDefinitionInfo,
  getExistingSchedules,
  UpdateClientSchedule,
  updateYamlFileContentSchedule
};
