<template>
  <div class="app-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>

    <div class="content">
      <h1>Client Pipeline Schedules</h1>

      <!-- Informational Section -->
      <section class="info-section">
        <p>
          This page allows you to view, manage, and update schedules for pipelines
          associated with different clients. To add or update a schedule for a pipeline,
          simply click on the respective pipeline row in the table below.
        </p>
        <p>
          Pipelines with existing schedules are listed under the "Clients with Scheduled
          Pipelines" section, while pipelines without schedules are listed under the
          "Clients without Scheduled Pipelines" section.
        </p>
        <p><strong>Click on any pipeline to configure its schedule.</strong></p>
      </section>

      <!-- Loading Bar -->
      <LoadingBar :loading="loading" :progressPercentage="progressPercentage" />

      <!-- Status Messages -->
      <div v-if="message.text" class="message" :class="message.type">
        {{ message.text }}
      </div>

      <section>
        <h2>Clients with Scheduled Pipelines</h2>
        <table>
          <thead>
            <tr>
              <th>Client</th>
              <th>Pipeline</th>
              <th>Schedules</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="client in clientsWithSchedules"
              :key="client.clientCode"
              @click="openModal(client, client.pipelineName, true)"
              class="clickable-row"
            >
              <td>{{ client.clientCode }}</td>
              <td>{{ client.pipelineName }}</td>
              <td>
                <ul>
                  <li v-for="schedule in client.schedules" :key="schedule.Cron">
                    {{ schedule.DisplayName }} (Cron: {{ schedule.Cron }})
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <h2>Clients without Scheduled Pipelines</h2>
        <table>
          <thead>
            <tr>
              <th>Client</th>
              <th>Pipeline</th>
              <th>Schedules</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="client in clientsWithoutSchedules"
              :key="client.clientCode"
              @click="openModal(client, client.pipelineName, false)"
              class="clickable-row"
            >
              <td>{{ client.clientCode }}</td>
              <td>{{ client.pipelineName }}</td>
              <td>No schedule has been configured</td>
            </tr>
          </tbody>
        </table>
      </section>

      <!-- Pipeline Schedule Modal -->
      <PipelineScheduleModal
        ref="pipelineModal"
        :show="isModalOpen"
        :client="selectedClient"
        :pipelineName="selectedPipelineName"
        :isUpdate="isUpdate"
        :availableTimes="availableTimes"
        @close="closeModal"
        @submit="handleScheduleSubmit"
      />
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/components/NavBar.vue";
import PipelineScheduleModal from "@/views/components/PipelineScheduleModal.vue";
import LoadingBar from "@/views/components/LoadingBar.vue";
import { msalInstance } from "@/auth.js";
import {
  getAllPipelineDefinitions,
  getPipelineDefinitionInfo,
  getExistingSchedules,
  UpdateClientSchedule,
} from "@/azure-devops-api";

export default {
  components: { NavBar, PipelineScheduleModal, LoadingBar },
  data() {
    return {
      allClients: [],
      clientsWithSchedules: [],
      clientsWithoutSchedules: [],
      selectedClient: null,
      selectedPipelineName: "",
      isModalOpen: false,
      isUpdate: false,
      availableTimes: [],
      message: { text: "", type: "" }, // Status message object
      loading: false, // To track loading state
      progressPercentage: 0, // To track loading progress
    };
  },
  computed: {
    isLoggedIn() {
      return !!msalInstance.getAllAccounts().length;
    },
  },
  async created() {
    await this.loadPipelineSchedules();
  },
  methods: {
    async loadPipelineSchedules() {
      this.loading = true;
      this.progressPercentage = 0;
      try {
        const accessToken = await this.getAccessToken();
        const projectName = "Tenants";

        const pipelineDefinitions = await getAllPipelineDefinitions(
          "cyaniccloud",
          projectName,
          accessToken
        );

        const totalPipelines = pipelineDefinitions.length;
        let processedPipelines = 0;

        const clients = await Promise.all(
          pipelineDefinitions.map(async (pipeline) => {
            const pipelineInfo = await getPipelineDefinitionInfo(
              pipeline.name,
              pipeline.path,
              "cyaniccloud",
              projectName,
              accessToken
            );

            if (!pipelineInfo) return null;

            const schedules = await getExistingSchedules(
              "cyaniccloud",
              pipelineInfo.projectId,
              projectName,
              pipelineInfo.definitionId,
              pipeline.path,
              accessToken
            );

            processedPipelines++;
            this.progressPercentage = Math.floor(
              (processedPipelines / totalPipelines) * 100
            );

            return {
              clientCode: pipeline.path.replace("\\", "").trim(),
              pipelineName: pipeline.name,
              schedules: schedules || [],
            };
          })
        );

        this.clientsWithSchedules = clients.filter(
          (client) => client && client.schedules.length > 0
        );
        this.clientsWithoutSchedules = clients.filter(
          (client) => client && client.schedules.length === 0
        );

        this.allClients = [
          ...this.clientsWithSchedules,
          ...this.clientsWithoutSchedules,
        ];

        this.message = {
          text: "Pipeline schedules loaded successfully!",
          type: "success",
        };
      } catch (error) {
        console.error("Error loading pipeline schedules:", error);
        this.message = {
          text: "Error loading pipeline schedules.",
          type: "error",
        };
      } finally {
        this.loading = false;
        this.progressPercentage = 0;
      }
    },
    generateAvailableTimes() {
      const times = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const formattedHour = hour.toString().padStart(2, "0");
          const formattedMinute = minute.toString().padStart(2, "0");
          times.push(`${formattedHour}:${formattedMinute}`);
        }
      }
      return times;
    },
    openModal(client, pipelineName, isUpdate) {
      console.log("Opening modal:", { client, pipelineName, isUpdate });
      this.selectedClient = client;
      this.selectedPipelineName = pipelineName;
      this.availableTimes = this.generateAvailableTimes();
      this.isModalOpen = true;
      this.isUpdate = isUpdate;

      const allSchedules = this.clientsWithSchedules.flatMap((client) =>
        client.schedules.map((schedule) => ({
          cron: schedule.Cron,
          clientCode: client.clientCode,
          pipelineName: client.pipelineName,
        }))
      );

      this.$refs.pipelineModal.setSchedules(allSchedules);
    },
    closeModal() {
      console.log("Closing modal");
      this.isModalOpen = false;
      this.selectedClient = null;
      this.selectedPipelineName = "";
      this.isUpdate = false;
    },
    async handleScheduleSubmit(scheduleData) {
      try {
        const { day, time, client, pipelineName } = scheduleData;

        const cronExpression = this.convertToCron(day, time);
        const formattedTime = this.formatTime(time);

        const scheduleDetails = {
          cronExpression: cronExpression,
          displayName: `Weekly ${formattedTime} build (${day})`,
        };

        const accessToken = await this.getAccessToken();
        const projectName = "Tenants";

        const pipelineInfo = await getPipelineDefinitionInfo(
          pipelineName,
          `\\${client.clientCode}`,
          "cyaniccloud",
          projectName,
          accessToken
        );

        if (!pipelineInfo) {
          this.message = {
            text: "Pipeline information not found.",
            type: "error",
          };
          return;
        }

        await UpdateClientSchedule(
          "cyaniccloud",
          pipelineInfo.projectId,
          projectName,
          pipelineInfo.definitionId,
          scheduleDetails,
          accessToken
        );

        this.message = {
          text: "Schedule updated successfully!",
          type: "success",
        };
        this.closeModal();
        await this.loadPipelineSchedules();
      } catch (error) {
        console.error("Error updating schedule:", error);
        this.message = { text: "Failed to update schedule.", type: "error" };
      }
    },
    convertToCron(day, time) {
      const [hour, minute] = time.split(":").map(Number);
      const dayIndexMap = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
      };
      const dayIndex = dayIndexMap[day];
      return `${minute} ${hour} * * ${dayIndex}`;
    },
    formatTime(time) {
      const [hour, minute] = time.split(":").map(Number);
      return `${hour % 12 || 12}:${String(minute).padStart(2, "0")} ${
        hour >= 12 ? "PM" : "AM"
      }`;
    },
    async getAccessToken() {
      const accounts = msalInstance.getAllAccounts();
      if (!accounts.length) throw new Error("User is not logged in");
      const accessTokenRequest = {
        account: accounts[0],
        scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
      };
      const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
      return response.accessToken;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  background-color: #f5f7fa;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0e223b;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.top-bar .logo {
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
  margin-left: 20px;
}

.nav-bar {
  background: #0e223b;
  padding: 10px 0;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38b35f;
}

/* Content styling - ensuring Roboto font consistency */
.content {
  width: 90%;
  margin: 20px auto;
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
}

h1,
h2 {
  color: #0e223b;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

section {
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 15px;
  table-layout: fixed;
  font-family: "Roboto", sans-serif;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: left;
  vertical-align: middle;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Roboto", sans-serif;
}

table th {
  background-color: #0e223b;
  color: white;
  font-weight: 600;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

table td {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

table tbody tr:nth-child(even) {
  background-color: #fff;
}

table tbody tr:hover {
  background-color: #e3f2fd;
}

table td ul {
  padding-left: 20px;
  list-style: disc;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

table td ul li {
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
}

form {
  margin-top: 20px;
  font-family: "Roboto", sans-serif;
}

form div {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

form label {
  display: block;
  font-weight: bold;
  color: #0e223b;
  margin-bottom: 8px;
  font-family: "Roboto", sans-serif;
}

form select,
form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 15px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-family: "Roboto", sans-serif;
}

form select:focus,
form input:focus {
  outline: none;
  border-color: #38b35f;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(56, 179, 95, 0.3);
}

form button {
  background-color: #0e223b;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-family: "Roboto", sans-serif;
}

form button:hover {
  background-color: #38b35f;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

ul {
  padding-left: 20px;
  list-style: disc;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

ul li {
  margin-bottom: 5px;
  color: #555;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

ul li:hover {
  color: #0e223b;
}

.clickable-row {
  cursor: pointer;
}
.clickable-row:hover {
  background-color: #f0f8ff;
}

.info-section {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}

.info-section p {
  margin: 10px 0;
}

.message {
  margin: 20px 0;
  padding: 15px;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
</style>
